import React from 'react'

export default function Pagination({
	onPrevious,
	onNext,
	isNextDisabled,
	isPreviousDisabled,
	isDarkMode,
}) {
	return (
		<nav
			aria-label="Pagination"
			className="flex items-center justify-center mb-8"
		>
			<div className="flex justify-center gap-3">
				<button
					onClick={onPrevious}
					disabled={isPreviousDisabled}
					className={`relative inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold ring-1 ring-inset 
          ${
						isDarkMode
							? 'bg-[#22272e] text-gray-300 ring-gray-700 hover:bg-gray-800 disabled:opacity-50'
							: 'bg-white text-gray-900 ring-gray-300 hover:bg-gray-50 disabled:opacity-50'
					} focus-visible:outline-offset-0`}
				>
					Previous
				</button>
				<button
					onClick={onNext}
					disabled={isNextDisabled}
					className={`relative inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold ring-1 ring-inset 
          ${
						isDarkMode
							? 'bg-[#22272e] text-gray-300 ring-gray-700 hover:bg-gray-800 disabled:opacity-50'
							: 'bg-white text-gray-900 ring-gray-300 hover:bg-gray-50 disabled:opacity-50'
					} focus-visible:outline-offset-0`}
				>
					Next
				</button>
			</div>
		</nav>
	)
}
