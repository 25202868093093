import React, { useState, useCallback, useEffect } from 'react'
import Downshift from 'downshift'
import axios from 'axios'

import { formatCity } from './convertState'

import './autocomplete.css'

const renderListItem = (
	city,
	index,
	getItemProps,
	highlightedIndex,
	// selectedItem,
	isDarkMode
) => {
	const { city: cityName, state_id } = city
	// const isSelected = selectedItem === cityName
	const isHighlighted = highlightedIndex === index

	return (
		<li
			className={`dropdown-item ${isDarkMode ? 'dark-mode' : 'light-mode'} ${
				isHighlighted ? 'is-highlighted' : ''
			}`}
			// style={{
			// 	color: '#121212',
			// 	backgroundColor: isHighlighted ? '#e6e6e6' : 'white',
			// 	fontWeight:
			// 		selectedItem?.city === cityName && selectedItem?.state_id === state_id
			// 			? 'bold'
			// 			: 'normal', // Check both city and state_id
			// }}
			{...getItemProps({ key: index, index, item: city })} // Pass the whole object here
		>
			{`${cityName}, ${state_id}`}
		</li>
	)
}

export default function AutocompleteCityDestination(props) {
	const {
		handleDestinationChange,
		style,
		className,
		name,
		destination,
		isDarkMode,
	} = props

	const [cities, setCities] = useState([])
	const [searchTerm, setSearchTerm] = useState('')

	function handleCityChange(selectedCity) {
		handleDestinationChange(selectedCity)
	}

	const fetchCities = useCallback(async value => {
		if (!value || value.length < 3) {
			return setCities([])
		}

		try {
			const response = await axios.get('/api/geocode', {
				params: { query: value },
			})
			const finalResult = formatCity(response.data.features)
			setCities(finalResult)
		} catch (error) {
			console.error('Error fetching suggestions:', error)
		}
	}, [])

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			if (searchTerm !== '') {
				fetchCities(searchTerm)
			}
		}, 300) // 300ms delay

		return () => clearTimeout(timeoutId)
	}, [searchTerm, fetchCities])

	const onInputValueChange = value => {
		setSearchTerm(value || '')
		if (!value || value.length < 1) {
			handleCityChange(null)
			setCities([])
		}
	}

	return (
		<Downshift
			selectedItem={destination}
			onInputValueChange={onInputValueChange}
			onChange={handleCityChange}
			itemToString={item =>
				item && item.city && item.state_id
					? `${item.city}, ${item.state_id}`
					: ''
			}
		>
			{({
				getInputProps,
				getItemProps,
				getMenuProps,
				isOpen,
				highlightedIndex,
				selectedItem,
			}) => (
				<div className="autocomplete-container">
					<input {...getInputProps({ className, name, style })} />

					<ul
						className={`dropdown-menu ${
							isDarkMode ? 'dark-mode' : 'light-mode'
						}`}
						{...getMenuProps()}
					>
						{isOpen &&
							cities.map((city, index) =>
								renderListItem(
									city,
									index,
									getItemProps,
									highlightedIndex,
									selectedItem,
									isDarkMode
								)
							)}
					</ul>
				</div>
			)}
		</Downshift>
	)
}
