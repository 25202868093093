import React from 'react'

import AutocompleteCityDestination from '../../Autocomplete/AutocompleteCityDestination'

function DestinationFilters(props) {
	const {
		filters,
		handleFilterChange,
		handleDestinationChange,
		labels,
		styles,
		isDarkMode,
	} = props

	const handleLabelClick = e => {
		// Use the parent element to scope the query to only this component's input
		const container = e.target.closest('.input-container')
		if (container) {
			const inputElement = container.querySelector('input')
			if (inputElement) {
				inputElement.focus()
			}
		}
	}

	// Extend styles to ensure consistent height with select
	const inputStyles = {
		...styles,
		height: '48px', // Increased height to match the select element
		boxSizing: 'border-box',
	}

	// Common focus styles for both input and select
	const focusRingClass =
		'focus:ring focus:ring-opacity-50 focus:border-blue-300 focus:ring-blue-200'

	// Input class with focus ring styling
	const inputClassName = `input input-bordered join-item shrink mt-1 p-2 rounded-md w-full ${focusRingClass}`

	// Select class with matching focus ring styling
	const selectClassName = `select select-bordered join-item mt-1 p-0 rounded-md w-full ${focusRingClass}`

	return (
		<div className="join col-span-5 flex">
			<div className="input-container flex-grow">
				<AutocompleteCityDestination
					destination={filters.destination}
					style={inputStyles}
					className={inputClassName}
					name="destination"
					handleDestinationChange={handleDestinationChange}
					isDarkMode={isDarkMode}
				/>
				<label
					htmlFor="origin-input"
					className={filters.destination?.city ? 'filled' : ''}
					style={{ ...labels, backgroundColor: 'transparent' }}
					onClick={handleLabelClick}
				>
					Destination
				</label>
			</div>

			{/* Destination Radius */}

			<div className="radius-select select-wrap">
				<label
					style={{
						...labels,
						backgroundColor: isDarkMode ? '#22272c' : '#f5f5f7',
						color: isDarkMode ? '#C9D1D9' : '#3a4353',
					}}
				>
					Radius
				</label>
				<select
					name="dropoffMaxDist"
					onChange={handleFilterChange}
					className={selectClassName}
					style={styles}
					value={filters.dropoffMaxDist}
					disabled={!filters.dropCity}
				>
					<option value={10}>10 mi</option>
					<option value={25}>25 mi</option>
					<option value={50}>50 mi</option>
					<option value={75}>75 mi</option>
					<option value={100}>100 mi</option>
					<option value={150}>150 mi</option>
					<option value={200}>200 mi</option>
					<option value={250}>250 mi</option>
				</select>
			</div>
		</div>
	)
}

export default DestinationFilters
