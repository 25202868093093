import { useEffect, useState } from 'react'
import Axios from 'axios'

import Load from './LoadSimilar'
import Spinner from '../../Loadboard/Spinner'

import { useDarkMode } from '../../../context/DarkModeContext'

import { queryFormatter } from '../loadboardHelpers'
import { baseURL } from '../../../config'

import './viewSimilarLoads.css'

function ViewSimilarLoads({ isModalOpen, closeModal, load }) {
	const { isDarkMode } = useDarkMode()

	const [loads, setLoads] = useState([])
	const [isLoading, setIsLoading] = useState(false)

	const handleOutsideClick = event => {
		if (event.target.tagName.toLowerCase() === 'dialog') {
			closeModal()
		}
	}

	useEffect(() => {
		if (isModalOpen) {
			const fetchSimilarLoads = async () => {
				setIsLoading(true)

				const response = await Axios.post(
					`${baseURL}/api/loads/similar`,
					queryFormatter(load)
				)

				setLoads(response?.data?.loads)

				setIsLoading(false)
			}

			fetchSimilarLoads()

			return () => {
				setLoads([])
			}
		}
	}, [isModalOpen, load])

	return (
		<div>
			<dialog
				onClick={handleOutsideClick}
				className={`modal ${isModalOpen ? 'modal-open' : ''}`}
			>
				<form
					method="dialog"
					className="modal-box"
					style={{
						minWidth: '1120px',
						maxHeight: 'auto',
						overflowY: 'auto',
						top: '5%',
						position: 'absolute',
						backgroundColor: isDarkMode ? '#1c2126' : '#f5f5f7',
						transition: 'background-color 0.3s ease',
					}}
				>
					<h2
						className={`ml-10 mb-2 font-bold text-lg ${
							isDarkMode ? 'text-gray-200' : 'text-gray-800'
						}`}
					>
						Similar Loads
					</h2>

					{isLoading ? (
						<div className="h-64">
							<Spinner isDarkMode={isDarkMode} similarLoads={true} />
						</div>
					) : Array.isArray(loads) && loads?.length ? (
						loads?.map(load => <Load key={load?._id} load={load} />)
					) : (
						<div
							className={`pt-24 flex justify-center items-center text-3xl font-medium ${
								isDarkMode ? 'text-gray-200' : 'text-gray-800'
							}`}
						>
							No matching data
						</div>
					)}

					<div className="modal-action">
						<button
							onClick={closeModal}
							style={{
								backgroundColor: isDarkMode ? '#2d333b' : '#e6e8ea',
								color: isDarkMode ? '#adbac7' : '#1c2128',
								padding: '8px 16px',
								borderRadius: '6px',
								border: isDarkMode ? '1px solid #444c56' : '1px solid #d0d7de',
								cursor: 'pointer',
								transition: 'all 0.2s ease',
							}}
							onMouseOver={e => {
								e.target.style.backgroundColor = isDarkMode
									? '#444c56'
									: '#d0d7de'
							}}
							onMouseOut={e => {
								e.target.style.backgroundColor = isDarkMode
									? '#2d333b'
									: '#e6e8ea'
							}}
						>
							Close
						</button>
					</div>
				</form>
			</dialog>
		</div>
	)
}

export default ViewSimilarLoads
