import React, { useState } from 'react'

import {
	MapPinIcon,
	MapIcon,
	DocumentDuplicateIcon,
	ArrowTrendingUpIcon,
	QueueListIcon,
	SparklesIcon,
	CheckBadgeIcon,
} from '@heroicons/react/20/solid'

import ViewOnMap from './ViewOnMap'
import ViewSimilarLoads from './ViewSimilarLoads'
import LoadPriceChart from './LoadPriceChart'

import './loadcollapse.css'

function LoadCollapse({ load, isDarkMode, styles, isSimilarLoads }) {
	const [isCopied, setIsCopied] = useState(false)
	const [isMapModalOpen, setMapModalOpen] = useState(false)
	const [isSimilarLoadsModalOpen, setSimilarLoadsModalOpen] = useState(false)

	const { broker, comments } = load

	const viewSimilarLoadsClick = event => {
		event.stopPropagation()
		setSimilarLoadsModalOpen(true)
	}

	const closeSimilarLoadsModal = () => {
		setSimilarLoadsModalOpen(false)
	}

	const viewOnMapClick = event => {
		event.stopPropagation()
		setMapModalOpen(true)
	}

	const closeMapModal = () => {
		setMapModalOpen(false)
	}

	const copyHandler = event => {
		event.stopPropagation()

		const textToCopy = `Company: ${broker.company}\nCompany Name: ${broker.companyName}\nPhone: ${broker.phone}`

		const textarea = document.createElement('textarea')
		textarea.value = textToCopy
		document.body.appendChild(textarea)
		textarea.select()

		if (document.execCommand('copy')) {
			setIsCopied(true)
		} else {
			console.error('Failed to copy text')
		}
		document.body.removeChild(textarea)
	}

	return (
		<div
			className="max-w-screen-xl mx-auto col-span-12 transition-all border-solid border px-2 py-3 rounded-md"
			style={{ ...styles, height: 'auto', overflow: 'hidden' }}
		>
			{/* Horizontal Divider */}
			<div className="col-span-12 h-px my-2"></div>

			<ViewOnMap
				stops={load?.loads}
				isModalOpen={isMapModalOpen}
				closeModal={closeMapModal}
				isDarkMode={isDarkMode}
			/>

			{/* Collapsible content goes here */}
			<div className="grid grid-cols-12 gap-4">
				{/* Column 1: Load Stops */}
				<div
					className={`col-span-3 ${
						isDarkMode ? 'loadcollapse-dark' : 'loadcollapse-light'
					} p-4 rounded`}
				>
					<div className="flex items-center mb-2">
						<MapPinIcon className="h-6 w-6 mr-1" aria-hidden="true" />
						<h3 className="text-lg font-semibold">Load Stops</h3>{' '}
						{/* Add AI Bookable tag */}
						{load.isAIEnabled && (
							<div className="flex items-center ml-3">
								<SparklesIcon
									className="h-4 w-4 mr-1 text-purple-400"
									aria-hidden="true"
								/>
								<span className="text-sm font-semibold text-purple-400">
									AI Bookable
								</span>
							</div>
						)}
					</div>

					{!isSimilarLoads && (
						<button
							onClick={viewOnMapClick}
							className="flex items-center bg-cyan-500 text-white px-3 py-1 rounded mb-4"
						>
							<MapIcon className="h-5 w-5" aria-hidden="true" />
							<span className="ml-2">View On Map</span>
						</button>
					)}

					{load?.loads?.map((stop, index) => (
						<div className="flex items-center mb-2" key={index}>
							<span
								className={`${
									isDarkMode ? 'text-gray-200' : 'text-gray-700'
								} font-bold mr-2`}
							>
								{index + 1}.
							</span>
							<p className="text-sm">
								{stop?.label && stop?.label} {stop?.city}, {stop?.state}
								<span
									className={`ml-2 ${
										isDarkMode ? 'text-blue-600' : 'text-blue-500'
									} text-bold`}
								>
									{stop?.isBobtail ? 'Bobtail' : ''}
								</span>
							</p>
						</div>
					))}
				</div>

				{/* Column 2: Broker Info */}
				<div
					className={`col-span-3 ${
						isDarkMode ? 'loadcollapse-dark' : 'loadcollapse-light'
					} p-4 rounded`}
				>
					<div className="flex items-center mb-2">
						<CheckBadgeIcon className="h-6 w-6 mr-1" aria-hidden="true" />
						<h3 className="text-lg font-semibold">Performance Score</h3>
					</div>

					{!isSimilarLoads && (
						<button
							onClick={copyHandler}
							className="flex items-center bg-cyan-500 text-white px-3 py-1 rounded mb-4"
						>
							<DocumentDuplicateIcon className="h-4 w-4" aria-hidden="true" />
							<span className="ml-2">{isCopied ? 'Copied' : 'Copy Info'}</span>
						</button>
					)}

					<p className="text-sm text-gray-400 mb-2">
						Accessible by performance scores
					</p>

					<div className="flex flex-wrap gap-1.5">
						{load?.CP &&
							load.CP.filter(rating => rating !== 'NO_SCORE')
								.sort((a, b) => {
									const order = {
										A_PLUS: 1,
										A: 2,
										B_PLUS: 3,
										B: 4,
										C_PLUS: 5,
										C: 6,
										D_PLUS: 7,
										D: 8,
										F: 9,
									}
									return order[a] - order[b]
								})
								.map((rating, index) => (
									<span
										key={index}
										className="px-2 py-0.5 rounded-full text-xs font-medium"
										style={{
											backgroundColor: rating.includes('A')
												? '#86efac' // Green
												: rating.includes('B')
												? '#fde047' // Yellow
												: rating.includes('C')
												? '#fca5a5' // Light Red
												: rating.includes('D')
												? '#f87171' // Red
												: '#ef4444', // Dark Red for F
											color: '#1f2937',
										}}
									>
										{rating.replace('_PLUS', '+')}
									</span>
								))}
					</div>
				</div>

				{/* Column 3: Analytics */}
				<div
					className={`col-span-6 ${
						isDarkMode ? 'loadcollapse-dark' : 'loadcollapse-light'
					} p-4 rounded`}
				>
					<div className="flex items-center mb-2">
						<ArrowTrendingUpIcon className="h-6 w-6 mr-1" aria-hidden="true" />
						<h3 className="text-lg font-semibold">Analytics</h3>
					</div>

					{!isSimilarLoads && (
						<>
							<ViewSimilarLoads
								isModalOpen={isSimilarLoadsModalOpen}
								closeModal={closeSimilarLoadsModal}
								load={load}
							/>

							<button
								onClick={viewSimilarLoadsClick}
								className="flex items-center bg-cyan-500 text-white px-3 py-1 rounded mb-4"
							>
								<QueueListIcon className="h-4 w-4" aria-hidden="true" />
								<span className="ml-2">View Similar Loads</span>
							</button>
						</>
					)}

					<LoadPriceChart isDarkMode={isDarkMode} load={load} />
				</div>
			</div>
		</div>
	)
}

export default LoadCollapse
