import React from 'react'
import { FunnelIcon } from '@heroicons/react/24/outline'

const SortyByLoads = ({ isDarkMode, ...props }) => {
	return (
		<div className="relative inline-flex items-center">
			<FunnelIcon
				className={`absolute left-2 w-3.5 h-3.5 ${
					isDarkMode ? 'text-gray-400' : 'text-gray-500'
				}`}
			/>
			<select
				{...props}
				className={`appearance-none rounded-md pl-7 pr-3 py-1.5 text-sm font-medium cursor-pointer focus:outline-none focus:ring-1 ${
					isDarkMode
						? 'bg-[#22272e] text-gray-200 border border-[#444c56] focus:ring-[#444c56] hover:bg-[#2d333b]'
						: 'bg-white text-gray-700 border border-gray-300 focus:ring-gray-300 hover:bg-gray-50'
				}`}
				style={{
					width: '140px',
					WebkitAppearance: 'none',
					MozAppearance: 'none',
				}}
			>
				<option value="pickupDate">Start date</option>
				<option value="ppm">Price per mile</option>
				<option value="payout">Payout</option>
				<option value="newest">Last seen</option>
			</select>
		</div>
	)
}

export default SortyByLoads
