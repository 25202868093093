import Axios from 'axios'
import { baseURL } from '../config'
import { getCurrentUser } from './authService'
// import { convertState } from './stateConversion'

const userToken = localStorage.getItem('jwtToken')

let cancelToken
let cancelTokenShowSearch

// if (userToken) {
// 	Axios.interceptors.request.use(function (config) {
// 		config.headers.authorization = userToken

// 		return config
// 	})
// }

Axios.interceptors.request.use(
	function (config) {
		const token = localStorage.getItem('jwtToken')
		if (token) {
			config.headers.authorization = token
		}
		return config
	},
	function (error) {
		return Promise.reject(error)
	}
)

export const getPatterns = async (
	{
		dropLat,
		dropLng,
		pickupLat,
		pickupLng,
		source,
		equipments,
		stopCount,
		loadDist,
	},
	signal
) => {
	const dropoffData = JSON.stringify({
		lat: `${dropLat}`,
		lng: `${dropLng}`,
		maxDist: '50',
	})

	const pickupData = JSON.stringify({
		lat: `${pickupLat}`,
		lng: `${pickupLng}`,
		maxDist: '50',
	})
	const equip = JSON.stringify(equipments)
	let sourceData = !source ? 'AMAZON' : JSON.stringify(source)

	try {
		const result = await Axios.get(
			`${baseURL}/api/loads/pattern?day=${7}&dropOff=${dropoffData}&pickup=${pickupData}&source=[${sourceData}]&equipments=[${equip}]&stopCount=${
				stopCount || 2
			}&averageDistance=${loadDist || null}`,
			{ signal: signal }
		)
		return result
	} catch (err) {
		console.log(err)
	}
}

export const getAnalitics = async () => {
	try {
		const result = await Axios.get(`${baseURL}/api/analitics`)
		return result.data
	} catch (err) {
		console.log(err)
	}
}

export const getLoadboards = async userId => {
	try {
		const result = await Axios.get(
			`${baseURL}/api/users/${userId}/loadboards/all`
		)
		return result.data.boardCredentials
	} catch (err) {
		console.log(err, 'err from getLoadboards')
	}
}

export const addLoadboardCreds = async creds => {
	try {
		const result = await Axios.put(
			`${baseURL}/api/users/loadboards/add`,
			creds,
			{
				headers: {
					authorization: userToken,
				},
			}
		)
		return result
	} catch (err) {
		console.log(err, 'err from addLoadboardCreds')
		throw err
	}
}

export const removeLoadboardCreds = async (loadboard, userId) => {
	const creds = {
		loadboard: loadboard,
		userId: userId,
	}
	try {
		const result = await Axios.put(
			`${baseURL}/api/users/loadboards/remove`,
			creds
		)
		return result
	} catch (err) {
		console.log(err, 'err from removeLoadboardCreds')
		throw err
	}
}

export const deleteSearch = async searchId => {
	try {
		const result = await Axios.delete(`${baseURL}/api/scraper/${searchId}`)

		return result
	} catch (error) {
		console.error(error)
	}
}

export const deleteAllSearches = async userId => {
	try {
		const result = await Axios.delete(
			`${baseURL}/api/scraper/deleteAllStopped/${userId}`
		)

		return result
	} catch (error) {
		console.error(error)
	}
}

const formatCity = objs => {
	const formattedData = objs.map(obj => {
		const [city, state_name, country] = obj.place_name.split(',')
		const state_id = state_name //convertState(state_name)
		const [lng, lat] = obj.center

		return { city, state_id, lng, lat }
	})

	return formattedData
}

export const nearCity = async ({
	dropoffMaxDist,
	pickupMaxDist,
	equipType,
	dropLat,
	dropLng,
	pickupLng,
	pickupLat,
	price,
	sourceType,
	paginateSize,
	pricePerMile,
	deadHeadShortest,
	startDateNearest,
	loadsNewest,
	stopCount,
	weight,
	distance,
	maxdistance,
	limited = true,
	ignoreLoadOut,
	excludeBroker,
	excludeOrIncludeBrokers,
	lastSeen = 1000,
	bobtail,
	isAIEnabled,
	sortBy,
	setPaymentRequired,
	driverType,
	carrierGrades,
}) => {
	setPaymentRequired(false)

	weight = Number(weight)
	stopCount = Number(stopCount)

	//Check if there are any previous pending requests
	if (typeof cancelToken != typeof undefined) {
		cancelToken.cancel('Operation canceled due to new request.')
	}

	//Save the cancel token for the current request
	cancelToken = Axios.CancelToken.source()

	const loadsNew = JSON.stringify(loadsNewest)

	const dShortest = JSON.stringify(deadHeadShortest)
	const startDNearest = JSON.stringify(startDateNearest)
	const pPerMile = JSON.stringify(pricePerMile)

	const dropOff =
		dropLat && dropLng
			? JSON.stringify({
					lat: dropLat,
					lng: dropLng,
					maxDist: dropoffMaxDist,
			  })
			: null

	const pickup =
		pickupLat && pickupLng
			? JSON.stringify({
					lat: pickupLat,
					lng: pickupLng,
					maxDist: pickupMaxDist,
			  })
			: null

	const formatSource = sourceType?.map(s => s.value)

	let source =
		!formatSource || !formatSource.length
			? JSON.stringify([
					'DAT',
					'AMAZON',
					'EMERGE',
					'COYOTE',
					'TQL',
					'XPO',
					'SWIFT',
					'JB HUNT',
			  ])
			: JSON.stringify(formatSource)

	let equip =
		!equipType || equipType === 'All Equipments' || equipType === 'Any'
			? null
			: JSON.stringify([equipType])

	const excludedBrokers = excludeBroker
		? JSON.stringify(excludeBroker?.map(b => b?.value))
		: null

	const carrierGradesParam = carrierGrades?.length
		? JSON.stringify(carrierGrades)
		: null

	let result

	let sortType = ''
	if (sortBy === 'ppm') sortType = { pricePerMile: -1 }
	else if (sortBy === 'newest') sortType = { updatedAt: -1 }
	else if (sortBy === 'pickupDate') sortType = { pickupDate: 1 }
	else if (sortBy === 'payout') sortType = { price: -1 }
	else sortType = { pricePerMile: -1 }

	const stringSortBy = JSON.stringify(sortType)

	try {
		result = await Axios.get(
			`${baseURL}/api/loads?${dropOff ? 'dropOff=' + dropOff : ''}${
				pickup ? '&pickup=' + pickup : ''
			}&price=${price ? price : 0}&pricePerMile=${
				pricePerMile >= 0 &&
				pricePerMile !== true &&
				pricePerMile !== false &&
				pricePerMile !== null
					? pricePerMile
					: 0
			}&stopCount=${stopCount || 0}&weight=${
				weight || 0
			}&sortBy=${stringSortBy}&limited=${limited}&source=${source}&equipments=${equip}&paginate={"size":"50","number":"${
				paginateSize || 1
			}"}&limitedLoads=${!pickup ? 1 : 0}&distance=${
				distance ? distance : ''
			}&maxdistance=${maxdistance ? maxdistance : ''}&ignoreLoadOut=${
				ignoreLoadOut ? 'LOAD_OUT' : false
			}&bobtail=${
				bobtail ? 'bobtail' : false
			}&lastSeen=${lastSeen}&excludedBrokers=${excludedBrokers}&excludeOrIncludeBrokers=${
				excludeOrIncludeBrokers || null
			}&driverType=${driverType || ''}&isAIEnabled=${
				isAIEnabled ? 'true' : 'false'
			}${carrierGradesParam ? '&carrierGrades=' + carrierGradesParam : ''}`,
			{
				cancelToken: cancelToken.token,
			}
		)

		if (result.data?.message === 'Failed to authenticate the token!') {
			localStorage.removeItem('jwtToken')
			window.location = '/'
			return window.location.reload()
		}

		setPaymentRequired(false)

		return result.data
	} catch (err) {
		if (err.message.includes('401')) {
			// open sign in modal
			const modalForOtherAccountLoggedIn = document.querySelector(
				'#modalForOtherAccountLoggedIn'
			)

			if (modalForOtherAccountLoggedIn) {
				modalForOtherAccountLoggedIn.classList.add('modal-open')
			}

			await (async () => {
				try {
					await Axios.post('/api/user/logout-session')
					localStorage.removeItem('jwtToken')
					localStorage.removeItem('email')
				} catch (logoutError) {
					console.error('Error logging out:', logoutError)
				}
			})()
		}

		if (Axios.isAxiosError(err) && err.response?.status === 402) {
			// Payment required error
			setPaymentRequired(true)
		}

		// return err
		throw err
	}
}

export const showCity = async ({
	totalPrice,
	sourceType,
	paginateSize,
	searchId,
	lat,
	lng,
	pricePerMile,
	deadHeadShortest,
	startDateNearest,
	loadsNewest,
	stopCount,
	weight,
}) => {
	//Check if there are any previous pending requests
	if (typeof cancelTokenShowSearch != typeof undefined) {
		cancelTokenShowSearch.cancel('Operation canceled due to new request.')
	}

	//Save the cancel token for the current request
	cancelTokenShowSearch = Axios.CancelToken.source()

	const dShortest = JSON.stringify(deadHeadShortest)
	const startDNearest = JSON.stringify(startDateNearest)
	const pPerMile = JSON.stringify(pricePerMile)
	const loadsNew = JSON.stringify(loadsNewest)

	const sortByPickupDate = JSON.stringify({
		pickupDate: startDateNearest ? -1 : 1,
	})

	const sortByPricePerMile = JSON.stringify({
		pricePerMile: pricePerMile ? -1 : 1,
	})

	const sortyByLoadsNew = JSON.stringify({
		updatedAt: loadsNewest ? -1 : 1,
	})

	const sortByDeadHead = JSON.stringify({
		deadhead: {
			lat: lat,
			lng: lng,
			desc: dShortest,
		},
	})

	let source =
		!sourceType || sourceType === '' || sourceType === 'All Sources'
			? null
			: JSON.stringify([sourceType])

	try {
		const result = await Axios.get(
			`${baseURL}/api/scrape?searchId=${searchId}&price=${
				totalPrice ? totalPrice : 0
			}&pricePerMile=${
				pricePerMile >= 0 &&
				pricePerMile !== true &&
				pricePerMile !== false &&
				pricePerMile !== null
					? pricePerMile
					: 0
			}&sortBy=${
				loadsNew !== 'null'
					? sortyByLoadsNew
					: startDNearest !== 'null'
					? sortByPickupDate
					: dShortest !== 'null'
					? sortByDeadHead
					: pPerMile !== 'null'
					? sortByPricePerMile
					: sortByDeadHead
			}&stopCount=${stopCount || 0}&weight=${
				weight || 0
			}&paginate={"size":"20","number":"${
				paginateSize || 1
			}"}&source=${source}&pickup={"lat": ${lat}, "lng": ${lng}}`,
			{
				cancelTokenShowSearch: cancelTokenShowSearch.token,
				cancelToken: cancelTokenShowSearch.token,
			}
		)

		return result
	} catch (error) {
		console.log(error)
		return error.message
	}
}

export const showAllFoundLoads = async ({
	price,
	source,
	ppm,
	startDateNearest,
	loadsNewest,
	stopCount,
	weight,
	offsetCount,
	limit,
	equipment,
	pricePerMile,
	searchId,
}) => {
	const { id } = getCurrentUser()

	//Check if there are any previous pending requests
	if (typeof cancelTokenShowSearch != typeof undefined) {
		cancelTokenShowSearch.cancel('Operation canceled due to new request.')
	}

	//Save the cancel token for the current request
	cancelTokenShowSearch = Axios.CancelToken.source()

	const startDNearest = JSON.stringify(startDateNearest)
	const pPerMile = JSON.stringify(pricePerMile)
	const loadsNew = JSON.stringify(loadsNewest)

	const sortByPickupDate = JSON.stringify({
		pickupDate: startDateNearest ? -1 : 1,
	})

	const sortByPricePerMile = JSON.stringify({
		pricePerMile: pricePerMile ? -1 : 1,
	})

	const sortyByLoadsNew = JSON.stringify({
		updatedAt: loadsNewest ? -1 : 1,
	})

	let sourceType =
		!source || source === '' || source === 'All Sources'
			? null
			: JSON.stringify(source)

	const equip =
		!equipment || equipment === 'Any' ? 'null' : JSON.stringify(equipment)

	try {
		const result = await Axios.post(
			`${baseURL}/api/search/search-items?userId=${id}&equipment=${equip}&limit=${limit}&offsetCount=${offsetCount}&price=${
				price ? price : 0
			}&ppm=${
				ppm >= 0 && ppm !== true && ppm !== false && ppm !== null ? ppm : 0
			}&sortBy=${
				loadsNew !== 'null'
					? sortyByLoadsNew
					: startDNearest !== 'null'
					? sortByPickupDate
					: pPerMile !== 'null'
					? sortByPricePerMile
					: sortyByLoadsNew
			}&stopCount=${stopCount || 0}&weight=${
				weight || 0
			}&source=${sourceType}&searchId=${searchId}`,
			{
				cancelTokenShowSearch: cancelTokenShowSearch.token,
				cancelToken: cancelTokenShowSearch.token,
			}
		)

		return result
	} catch (error) {
		console.log(error)
		return error.message
	}
}

export const showBackhaul = async (
	{ day, dropLat, dropLng, pickupLng, pickupLat },
	signal2
) => {
	try {
		const result = await Axios.get(
			`${baseURL}/api/loads/backhaul?day=${
				day || 3
			}&dropOff={"lat":"${dropLat}","lng":"${dropLng}","maxDist":"50"}&pickup={"lat":"${pickupLat}","lng":"${pickupLng}","maxDist":"50"}`,
			{ signal: signal2 }
		)
		return result.data
	} catch (error) {
		console.log(error)
	}

	// api/loads/backhaul?day=1&dropOff={"lat":"40.92183","lng":"-76.051041","maxDist":"89"}&pickup={"lat":"39.087644","lng":"-84.725778","maxDist":"2"}
}

export const freeTrialReq = async data => {
	let res
	try {
		res = await Axios.post(`${baseURL}/api/free-trial-request`, data)

		return res
	} catch (err) {
		console.log(err)
	}
}

export const getFreeTrialReqUsers = async () => {
	let res

	try {
		res = await Axios.get(`${baseURL}/api/admin/free-trial-request`)

		return res
	} catch (err) {
		console.log(err)
	}
}

export const getUserInfoById = async id => {
	let res
	try {
		res = await Axios.get(`${baseURL}/api/admin/user?id=${id}`)
		return res
	} catch (err) {
		console.log(err)
	}
}

export const getBrokers = async broker => {
	try {
		const res = await Axios.get(`${baseURL}/api/broker?companyName=${broker}`)

		return res?.data?.map(br => ({
			value: br?.companyName,
			label: br?.companyName,
		}))
	} catch (err) {
		console.log(err)
	}
}

export const updateSearchNotification = async (searchId, newValue) => {
	// try {
	return Axios.put(
		`${baseURL}/api/search?searchId=${searchId}&newValue=${newValue}`
	)
	// } catch (err) {
	// 	console.log(err)
	// 	return err
	// }
}

export const fetchLoads = async () => {
	//  /api/loadboard/loads

	const response = await fetch(`${baseURL}/api/loadboard/loads?`)

	if (!response.ok) {
		throw new Error('An error occurred while fetching loads')
	}

	// await new Promise(resolve => setTimeout(resolve, 5000))

	return response.json()
}

export const loadBrokerOptions = async (query, loadedOptions) => {
	if (!query) return []

	const res = await getBrokers(query)

	return res
}

export const loadAnalytics = async (coordinates, equipmentType) => {
	try {
		const res = await Axios.get(
			`${baseURL}/api/load/analytics?coordinates=${coordinates}&equipmentType=${equipmentType}`
		)

		return res
	} catch (err) {
		console.log(err)
	}
}
