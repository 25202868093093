import { useState } from 'react'
import { Switch } from '@headlessui/react'
import { useDarkMode } from '../../../context/DarkModeContext'

import { updateSearchNotification } from '../../../services/httpService'

function classNames(...classes) {
	return classes.filter(Boolean).join(' ')
}

export default function NotificationToggle({ search, setShowToast, setError }) {
	const [enabled, setEnabled] = useState(search.isNotification || false)
	const { isDarkMode } = useDarkMode()

	const handleSwitch = async value => {
		try {
			await updateSearchNotification(search._id, value)
			setEnabled(value)
		} catch (err) {
			console.log('Error in NotificationToggle.js', err)
			setEnabled(!value)

			const errorMessage =
				err?.response?.data?.msg || err?.response?.data?.message

			setShowToast(true)
			setError(errorMessage || 'Something went wrong!')

			setTimeout(() => {
				setShowToast(false)
			}, 3000)
		}
	}

	return (
		<Switch.Group as="div" className="flex items-center">
			<Switch.Label as="span" className="mr-3 text-sm">
				<span
					className={`font-medium ${
						isDarkMode ? 'text-gray-100' : 'text-gray-900'
					}`}
				>
					Get notifications
				</span>
			</Switch.Label>

			<Switch
				checked={enabled}
				onChange={handleSwitch}
				style={{ backgroundColor: enabled ? '#22b0a5' : '#c6d4e2' }} // Set the color here
				className={classNames(
					'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-slate-600 focus:ring-offset-2'
				)}
			>
				<span
					aria-hidden="true"
					className={classNames(
						enabled ? 'translate-x-5' : 'translate-x-0',
						'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
					)}
				/>
			</Switch>
		</Switch.Group>
	)
}
