import { useState, useEffect } from 'react'
import { useQuery } from 'react-query'
import { LRUCache } from 'lru-cache'
import axios from 'axios'
import { Bars3Icon, SunIcon, MoonIcon } from '@heroicons/react/20/solid'

import Sidebar from '../Sidebar'
import LoadBoardFilters from './LoadBoardFilters'
import Load from './Load'
import Spinner from './Spinner'
import SortyByLoads from './SortyByLoads'
import ToggleFilter from './ToggleFilter'
import LoggedOutModal from '../AuthPages/LoggedOutModal'
import SelectedFilters from './SelectedFilters'
import DeviceManagementModal from './DeviceManagementModal'
import Toast from './Searches/Toast'

import { Logo } from '../LandingPage/Logo'

import './loadboard.css'
import 'daisyui/dist/full.css'

import { checkNewLoad } from './loadboardHelpers'
import { useDarkMode } from '../../context/DarkModeContext'
import { nearCity } from '../../services/httpService'
import { getCurrentUser } from '../../services/authService'

import { baseURL } from '../../config'

import UpgradeSection from './UpgradeSection'
import Pagination from './Pagination'

const initialFilters = {
	distance: '',
	driverType: '',
	maxdistance: '',
	dropLat: '',
	dropLng: '',
	dropCity: '',
	dropState: '',
	dropStateName: '',
	dropoffMaxDist: 100,
	pickupCity: '',
	pickupState: '',
	pickupStateName: '',
	pickupLat: '',
	pickupLng: '',
	pickupMaxDist: 50,
	equipType: '',
	pricePerMile: null,
	pricePerMileMin: '',
	price: '',
	payoutMin: '',
	sourceType: null,
	excludeBroker: null,
	excludeOrIncludeBrokers: true,
	paginateSize: 1,
	startDateNearest: null,
	deadHeadShortest: null,
	loadsNewest: true,
	stopCount: 0,
	weight: 0,
	limited: false,
	ignoreLoadOut: false,
	lastSeen: 30,
	applySavedFilters: false,
	bobtail: false,
	isAIEnabled: false,
	origin: '',
	destination: '',
	sortBy: 'newest',
	carrierGrades: [],
}

const loadCache = new LRUCache({ max: 300 })

export default function Loadboard() {
	const { isDarkMode, toggleDarkMode } = useDarkMode()

	const [currentPage, setCurrentPage] = useState(1)
	const itemsPerPage = 10

	const [sidebarOpen, setSidebarOpen] = useState(false)
	const [isAudioOn, setAudioOn] = useState(false)
	const [showScrollTop, setShowScrollTop] = useState(false)
	const [paymentRequired, setPaymentRequired] = useState(false)
	const [invoiceLoading, setInvoiceLoading] = useState(false)
	const [isAutoRefresh, setIsAutoRefresh] = useState(false)
	const [showDeviceModal, setShowDeviceModal] = useState(false)
	const [showToast, setShowToast] = useState(false)
	const [toastMessage, setToastMessage] = useState('')
	const [toastError, setToastError] = useState(false)

	const [filters, setFilters] = useState({
		distance: '',
		driverType: '',
		maxdistance: '',
		dropLat: '',
		dropLng: '',
		dropCity: '',
		dropState: '',
		dropStateName: '',
		dropoffMaxDist: 100,
		pickupCity: '',
		pickupState: '',
		pickupStateName: '',
		pickupLat: '',
		pickupLng: '',
		pickupMaxDist: 50,
		equipType: '',
		pricePerMile: null,
		price: '',
		payoutMin: '',
		sourceType: null,
		excludeBroker: null,
		excludeOrIncludeBrokers: true,
		paginateSize: 1,
		startDateNearest: null,
		deadHeadShortest: null,
		loadsNewest: true,
		stopCount: 0,
		weight: 0,
		limited: false,
		ignoreLoadOut: false,
		lastSeen: 30,
		applySavedFilters: false,
		bobtail: false,
		isAIEnabled: false,
		origin: '',
		destination: '',
		sortBy: 'newest',
		carrierGrades: [],
	})

	const { data, isLoading, isError, error, refetch, isFetching } = useQuery(
		['loads', { ...filters, page: currentPage, itemsPerPage }],
		() =>
			nearCity({
				...filters,
				number: currentPage,
				size: itemsPerPage,
				setPaymentRequired,
			}),
		{
			refetchOnWindowFocus: false,
			onSuccess: data => checkNewLoad(data, loadCache, isAudioOn),
			retry: (failureCount, error) => {
				// Don't retry on 429 (Too Many Requests) or 401 (Unauthorized)
				if (
					error?.response?.status === 429 ||
					error?.response?.status === 401
				) {
					return false
				}
				// Default React Query retry behavior for other errors (3 retries)
				return failureCount < 2
			},
		}
	)

	useEffect(() => {
		if (isError) {
			let errorMessage = ''

			if (typeof error === 'string') {
				errorMessage = error
			} else if (error instanceof Error) {
				errorMessage = error.message
			} else if (error?.response?.status) {
				errorMessage = `${error.response.status}`
			} else if (error?.message) {
				errorMessage = error.message
			} else {
				errorMessage = JSON.stringify(error)
			}

			console.log('Error message:', errorMessage)

			if (errorMessage.includes('429')) {
				// Show toast for too many requests
				setToastMessage('Too many requests being sent. Slow down!')
				setToastError(true)
				setShowToast(true)

				// Hide toast after 5 seconds
				setTimeout(() => {
					setShowToast(false)
				}, 5000)
			} else if (errorMessage.includes('401')) {
				setIsAutoRefresh(false)
			} else if (errorMessage.includes('403')) {
				setShowDeviceModal(true)
			}
		}
	}, [isError, error])

	const handlePrevPage = () => {
		setCurrentPage(prevPage => prevPage - 1)
		setFilters(prevFilters => ({
			...prevFilters,
			paginateSize: prevFilters.paginateSize - 1,
		})) // Decrement the paginateSize
	}

	const handleNextPage = () => {
		setCurrentPage(prevPage => prevPage + 1)
		setFilters(prevFilters => ({
			...prevFilters,
			paginateSize: prevFilters.paginateSize + 1,
		})) // Increment the paginateSize
	}

	const handleScroll = () => {
		setShowScrollTop(window.scrollY > 300)
	}

	const scrollToTop = () => {
		window.scrollTo({ top: 0, behavior: 'smooth' })
	}

	const handleSort = e => {
		const { name, value } = e.target

		setFilters({
			...filters,
			paginateSize: 1,
			[name]: value,
		})
	}

	const toggleSidebar = () => {
		setSidebarOpen(!sidebarOpen)
	}

	const resetFilters = () => {
		setFilters(initialFilters)
	}

	const removeFilter = key => {
		setFilters(prevFilters => ({
			...prevFilters,
			[key]: initialFilters[key],
		}))
	}

	useEffect(() => {
		refetch()
		setPaymentRequired(false)
	}, [filters, currentPage])

	useEffect(() => {
		window.addEventListener('scroll', handleScroll)
		return () => window.removeEventListener('scroll', handleScroll)
	}, [])

	const user = getCurrentUser()

	// fix here

	useEffect(() => {
		const fetchInvoice = async () => {
			try {
				const clientEmail = user?.email

				const location = window.location.pathname.includes('/loadboard')

				if (!clientEmail || !location) return

				// if (paymentRequired)
				setInvoiceLoading(true)

				const invoiceData = await axios.get(
					`${baseURL}/api/stripe/checkInvoice`
				)

				const dateFromServer = new Date(invoiceData?.data?.trialEndTime)
				const currentDate = new Date()

				const isDateInPast = dateFromServer < currentDate

				if (invoiceData?.data?.payed) {
					setPaymentRequired(false)

					if (paymentRequired) refetch()

					setInvoiceLoading(false)
					return
				}

				if (!isDateInPast) {
					setPaymentRequired(false)

					if (paymentRequired) refetch()

					setInvoiceLoading(false)
					return
				}

				setInvoiceLoading(false)
			} catch (error) {
				console.log('error', error)
				setInvoiceLoading(false)
			}
		}

		fetchInvoice()
	}, [])

	return (
		<>
			<div style={{ backgroundColor: '#1c2127' }}>
				<Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
				{/* <div> */}
				<div className="top-0 z-40 flex h-16 shrink-0 items-center gap-x-6 border-b border-gray-700 bg-0D1117 px-4 shadow-sm sm:px-6 lg:px-8">
					<button
						type="button"
						className="-m-2.5 p-2.5 text-white"
						onClick={toggleSidebar}
					>
						<span className="sr-only">Toggle sidebar</span>
						<Bars3Icon className="h-5 w-5" aria-hidden="true" />
					</button>

					<Logo isLoadboard={true} />

					<div className="flex items-center ml-auto">
						<p className="text-sky-500 font-light italic tracking-wide mr-3">
							{user?.email}
						</p>
						{isDarkMode ? (
							<SunIcon
								className="h-5 w-5 text-white cursor-pointer"
								aria-hidden="true"
								onClick={toggleDarkMode}
							/>
						) : (
							<MoonIcon
								className="h-5 w-5 text-white cursor-pointer"
								aria-hidden="true"
								onClick={toggleDarkMode}
							/>
						)}
					</div>
				</div>

				<main className={isDarkMode ? 'bg-dark-color' : 'bg-light-gray'}>
					<LoadBoardFilters
						isDarkMode={isDarkMode}
						refetch={refetch}
						filters={filters}
						setFilters={setFilters}
						resetFilters={resetFilters}
						isAudioOn={isAudioOn}
						setAudioOn={setAudioOn}
						setIsAutoRefresh={setIsAutoRefresh}
						isAutoRefresh={isAutoRefresh}
					/>

					<div
						className={`${
							isDarkMode ? 'bg-dark-color' : 'bg-light-gray'
						} w-full px-1 sm:px-6 lg:px-8 mb-1 -mt-1`}
					>
						<div className="max-w-screen-xl mx-auto flex min-h-[24px] items-center gap-x-1">
							<div className="flex flex-grow py-0.5">
								<SelectedFilters
									filters={filters}
									initialFilters={initialFilters}
									removeFilter={removeFilter}
								/>
							</div>

							<div className="flex items-center gap-x-2">
								<label
									className={`${
										isDarkMode ? 'text-white' : 'text-black'
									} whitespace-nowrap font-medium text-xs`}
								>
									{filters.limited ? 'Hide ' : 'Show '} Old Loads
								</label>
								<ToggleFilter
									show={filters.limited}
									setShow={() => {
										setFilters({
											...filters,
											limited: !filters.limited,
											paginateSize: 1,
										})
									}}
								/>

								<div className="block rounded-xs">
									<SortyByLoads
										onChange={handleSort}
										value={filters.sortBy}
										name="sortBy"
										isDarkMode={isDarkMode}
									/>
								</div>
							</div>
						</div>
					</div>

					<div className="mb-5">
						{paymentRequired ? (
							<UpgradeSection isDarkMode={isDarkMode} />
						) : isLoading || isFetching || invoiceLoading ? (
							<Spinner isDarkMode={isDarkMode} />
						) : Array.isArray(data?.loads) && data?.loads?.length ? (
							data?.loads?.map(load => (
								<Load
									key={load?._id}
									load={load}
									originLat={filters.pickupLat}
									originLng={filters.pickupLng}
								/>
							))
						) : (
							<div
								className={`${
									isDarkMode ? 'text-white' : 'text-black'
								} pt-24 flex justify-center items-center text-3xl font-medium text-slate-800`}
							>
								No matching data
							</div>
						)}
					</div>
				</main>

				{/* </div> */}
			</div>

			{/* Pagination controls */}
			{!isLoading &&
				!isFetching &&
				!invoiceLoading &&
				((Array.isArray(data?.loads) &&
					data?.loads?.length > 0 &&
					data?.totalResults > itemsPerPage) ||
					filters.paginateSize > 1) && (
					<div className="flex justify-center mb-8 mt-8">
						<Pagination
							onPrevious={handlePrevPage}
							onNext={handleNextPage}
							currentPage={filters.paginateSize}
							isPreviousDisabled={filters.paginateSize === 1}
							isNextDisabled={
								filters.paginateSize * itemsPerPage >=
									(data?.totalResults || 0) ||
								!data?.loads?.length ||
								data?.loads?.length < 50
							}
							isDarkMode={isDarkMode}
						/>
					</div>
				)}

			<LoggedOutModal />

			<DeviceManagementModal
				isOpen={showDeviceModal}
				onClose={() => setShowDeviceModal(false)}
				refetch={refetch}
				isDarkMode={isDarkMode}
			/>

			{showToast && <Toast message={toastMessage} error={toastError} />}
		</>
	)
}
