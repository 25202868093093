import React, { useEffect, useRef } from 'react'
import L from 'leaflet'

import 'leaflet-control-geocoder'
import 'leaflet-routing-machine'
import 'leaflet-routing-machine/dist/leaflet-routing-machine.css'
import 'leaflet/dist/leaflet.css'

import './loadcollapse.css'

function ViewOnMap({ isModalOpen, closeModal, stops, isDarkMode }) {
	const mapContainerRef = useRef(null)
	const map = useRef(null)

	const handleOutsideClick = event => {
		if (event.target.tagName.toLowerCase() === 'dialog') {
			closeModal()
		}
	}

	useEffect(() => {
		if (isModalOpen && stops?.length) {
			const bounds = new L.LatLngBounds()

			const summaryControl = L.control({ position: 'topright' })

			summaryControl.onAdd = function () {
				this._div = L.DomUtil.create('div', 'summary-control')
				this._div.style.backgroundColor = isDarkMode ? '#22272e' : '#ffffff'
				this._div.style.color = isDarkMode ? '#adbac7' : '#1f2937'
				this._div.style.border = isDarkMode
					? '1px solid #444c56'
					: '1px solid #e5e7eb'
				this._div.style.padding = '8px'
				this._div.style.borderRadius = '6px'
				this.update()
				return this._div
			}

			summaryControl.update = function (props) {
				const textColor = isDarkMode ? '#adbac7' : '#1f2937'
				this._div.innerHTML = props
					? `<span style="color: ${textColor}; font-weight: 600">Distance: ${(
							parseFloat(props.distance) * 0.621371
					  ).toFixed(2)} miles</span>
                 <br> <span style="color: ${textColor}; font-weight: 600">Time: ${
							props.time
					  } </span>`
					: 'No Route Selected'
			}

			if (!map.current) {
				map.current = L.map(mapContainerRef.current).setView(
					[stops[0].coordinates.lat, stops[0].coordinates.lng],
					10
				)

				L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png').addTo(
					map.current
				)

				summaryControl.addTo(map.current)
			}

			// Draw stops
			stops.forEach((stop, index) => {
				const customIcon = L.divIcon({
					className: 'custom-map-icon',
					html: `<div style="background-color: #c30000; width: 25px; height: 25px; border-radius: 50%; text-align: center; line-height: 25px; color: white">${
						index + 1
					}</div>`,
					iconSize: [25, 25],
					iconAnchor: [12, 12],
				})

				L.marker([stop.coordinates.lat, stop.coordinates.lng], {
					icon: customIcon,
				})?.addTo(map.current)

				bounds.extend([stop.coordinates.lat, stop.coordinates.lng])
			})

			map.current.fitBounds(bounds)

			const routingControl = L.Routing.control({
				waypoints: stops.map(stop =>
					L.latLng(stop.coordinates.lat, stop.coordinates.lng)
				),
				routeWhileDragging: false,
				show: false,
				createMarker: () => null,
				router: new L.Routing.OSRMv1({
					serviceUrl: 'https://router.project-osrm.org/route/v1',
				}),
			}).addTo(map.current)

			routingControl.on('routesfound', function (e) {
				const routes = e.routes
				const summary = routes[0].summary

				const formatter = new L.Routing.Formatter()

				summaryControl.update({
					distance: formatter.formatDistance(summary.totalDistance),
					time: formatter.formatTime(summary.totalTime),
				})
			})

			return () => {
				if (map.current) {
					map.current.off()
					map.current.remove()
					map.current = null
				}
			}
		}
	}, [isModalOpen, stops, isDarkMode])

	return (
		<div>
			<dialog
				onClick={handleOutsideClick}
				className={`modal ${isModalOpen ? 'modal-open' : ''}`}
			>
				<form
					method="dialog"
					className="modal-box"
					style={{
						minWidth: '1120px',
						maxHeight: 'auto',
						overflowY: 'auto',
						top: '5%',
						position: 'absolute',
						backgroundColor: isDarkMode ? '#1c2126' : '#f5f5f7',
						transition: 'background-color 0.3s ease',
					}}
				>
					<h2
						className={`font-bold text-lg mb-4 ${
							isDarkMode ? 'text-gray-200' : 'text-gray-800'
						}`}
					>
						Route on Map
					</h2>

					{/* Map container */}
					<div ref={mapContainerRef} className="map-container"></div>

					<div className="modal-action">
						<button
							onClick={closeModal}
							style={{
								backgroundColor: isDarkMode ? '#2d333b' : '#e6e8ea',
								color: isDarkMode ? '#adbac7' : '#1c2128',
								padding: '8px 16px',
								borderRadius: '6px',
								border: isDarkMode ? '1px solid #444c56' : '1px solid #d0d7de',
								cursor: 'pointer',
								transition: 'all 0.2s ease',
							}}
							onMouseOver={e => {
								e.target.style.backgroundColor = isDarkMode
									? '#444c56'
									: '#d0d7de'
							}}
							onMouseOut={e => {
								e.target.style.backgroundColor = isDarkMode
									? '#2d333b'
									: '#e6e8ea'
							}}
						>
							Close
						</button>
					</div>
				</form>
			</dialog>
		</div>
	)
}

export default ViewOnMap
