import React, { useEffect, useState } from 'react'
import Axios from 'axios'

import Toast from './Toast'

import { TrashIcon } from '@heroicons/react/24/solid'

import { getCurrentUser } from '../../../services/authService'
import { deleteSearch } from '../../../services/httpService'

import { baseURL } from '../../../config'
import styles from './UserSearches.module.css'

import NotificationToggle from './NotificationToggle'

function UserSearches({ isModalOpen, closeModal, setFilters, isDarkMode }) {
	const [searches, setSearches] = useState([])
	const [loading, setLoading] = useState(false)

	const [showToast, setShowToast] = useState(false)
	const [error, setError] = useState('')

	useEffect(() => {
		async function getSearchData() {
			setLoading(true)

			const user = await getCurrentUser()

			const data = await Axios.get(
				`${baseURL}/api/search/all?limit=${20}&skip=${0}`
			)

			setSearches(data.data.searches)

			data && setLoading(false)
		}

		if (isModalOpen) {
			getSearchData()
		}
	}, [isModalOpen])

	const handleOutsideClick = event => {
		if (event.target.tagName.toLowerCase() === 'dialog') {
			closeModal()
		}
	}

	const applyFilters = filters => {
		setFilters({ ...filters })
		closeModal()
	}

	const deleteSearchHandler = async id => {
		try {
			await deleteSearch(id)

			setSearches(searches => searches.filter(search => search._id !== id))
		} catch (error) {
			console.error('Failed to delete search', error)
		}
	}

	if (showToast && error) {
		closeModal()
		return (
			<>
				<Toast message={error} error={true} />
			</>
		)
	}

	return (
		<div>
			{isModalOpen && (
				<dialog
					onClick={handleOutsideClick}
					className={`modal ${isModalOpen ? 'modal-open' : ''}`}
				>
					<form
						method="dialog"
						className={`modal-box ${styles['searches-modal-box-custom']}`}
						style={{
							backgroundColor: isDarkMode ? '#1c2126' : '#f5f5f7',
							transition: 'background-color 0.3s ease',
						}}
					>
						<h3
							className={`mb-8 font-bold text-lg ${
								isDarkMode ? 'text-gray-200' : 'text-gray-800'
							}`}
						>
							Saved Searches
						</h3>
						{loading ? (
							<h3
								className={`my-12 text-xl text-center ${
									isDarkMode ? 'text-gray-200' : 'text-gray-700'
								}`}
							>
								Loading...
							</h3>
						) : searches.length > 0 ? (
							searches.map((search, index) => (
								<div
									key={index}
									style={{
										backgroundColor: isDarkMode ? '#22272d' : '#ffffff',
										borderColor: isDarkMode ? '#444c56' : '#e5e7eb',
									}}
									className="rounded-md shadow-md flex justify-between items-center border p-2 my-2"
								>
									<p className={isDarkMode ? 'text-gray-200' : 'text-gray-800'}>
										{search.searchName}
									</p>

									<button
										onClick={() => applyFilters(search.filters)}
										className={`text-sm mx-5 px-2 py-1 rounded transition duration-200 ${
											isDarkMode
												? 'text-gray-200 hover:text-gray-300'
												: 'text-gray-700 hover:text-gray-900'
										}`}
									>
										Apply filters
									</button>

									<div
										className={`border-l-2 mx-2 h-6 align-middle ${
											isDarkMode ? 'border-gray-700' : 'border-gray-200'
										}`}
									></div>

									<NotificationToggle
										search={search}
										setShowToast={setShowToast}
										setError={setError}
									/>

									<div
										className={`border-l-2 mx-2 h-6 align-middle ${
											isDarkMode ? 'border-gray-700' : 'border-gray-200'
										}`}
									></div>

									<TrashIcon
										onClick={() => deleteSearchHandler(search._id)}
										className="w-6 h-6 text-red-500 cursor-pointer"
									/>
								</div>
							))
						) : (
							<h3
								className={`my-12 text-xl text-center ${
									isDarkMode ? 'text-gray-200' : 'text-gray-700'
								}`}
							>
								You don't have any searches
							</h3>
						)}
						<div className="modal-action">
							<button
								onClick={closeModal}
								type="button"
								style={{
									backgroundColor: isDarkMode ? '#2d333b' : '#e6e8ea',
									color: isDarkMode ? '#adbac7' : '#1c2128',
									padding: '8px 16px',
									borderRadius: '6px',
									border: isDarkMode
										? '1px solid #444c56'
										: '1px solid #d0d7de',
									cursor: 'pointer',
									transition: 'all 0.2s ease',
								}}
								onMouseOver={e => {
									e.target.style.backgroundColor = isDarkMode
										? '#444c56'
										: '#d0d7de'
								}}
								onMouseOut={e => {
									e.target.style.backgroundColor = isDarkMode
										? '#2d333b'
										: '#e6e8ea'
								}}
							>
								Close
							</button>
						</div>
					</form>
				</dialog>
			)}
		</div>
	)
}

export default UserSearches
