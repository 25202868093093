/*global chrome*/

import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import jwtDecode from 'jwt-decode'

import { register, login } from '../../services/authService'

import { TextField } from './Fields'
import { Logo } from '../LandingPage/Logo'
import { SlimLayout } from './SlimLayout'
import { baseURL } from '../../config'

import './authPages.css'
import googleIcon from '../../media/icons/google.svg'

const sendTokenToChromeExtension = (extensionId, token) => {
	if (chrome && chrome.runtime && chrome.runtime.sendMessage) {
		chrome.runtime.sendMessage(extensionId, token)
	} else {
		console.log('Chrome extension messaging API not available.')
	}
}

export const metadata = {
	title: 'Sign Up',
}

export default function SignUp({
	sourceExtension,
	setSourceExtension,
	windowOpender,
	userToken,
}) {
	let location = useLocation()
	const navigate = useNavigate()

	const queryParams = new URLSearchParams(location.search)
	const redirectUri = queryParams.get('redirect_uri')

	const devURI = 'http://localhost:5001'
	const productionURI = ''
	const authUrl = !baseURL ? '' : baseURL
	// Check if we're on localhost and adjust the URL accordingly
	let googleAuthUrl = `${
		window.location.hostname === 'localhost' ? 'http://localhost:5001' : authUrl
	}/auth/google`

	if (redirectUri) {
		googleAuthUrl += `?redirect_uri=${redirectUri}`
	}

	const [loading, setLoading] = useState(false)
	// const [sourceExtension, setSourceExtension] = useState(false)

	const [state, setState] = useState({
		email: '',
		password: '',
		password2: '',
		errorMsg: null,
		loading: false,
		typeChanged: false,
		displayGoogleBtn: true,
		errorText: '',
	})

	const onChange = e => {
		e.preventDefault()

		const { name, value } = e.target
		setState({ ...state, [name]: value })
	}

	const showError = error => {
		setLoading(false)
		setState(prevState => ({
			...prevState,
			errorText: error,
		}))

		return setTimeout(() => {
			setState(prevState => ({
				...prevState,
				errorText: '',
			}))
		}, 8000)
	}

	const onSubmit = async e => {
		showError('')
		e.preventDefault()

		// Check if passwords match
		if (state.password !== state.password2) {
			return showError('Passwords do not match.')
		}

		try {
			setLoading(true)
			await register(state)

			const toPayments = true

			const loginSuccess = await login({
				user: state,
				location,
				navigate,
				sourceExtension,
				windowOpender,
				toPayments,
				redirectUri,
				userToken,
			})

			setLoading(false)

			if (loginSuccess) {
				// navigate('/payment')
				// navigate('/loadboard')

				if (redirectUri) {
					// window.location = redirectUri
					window.location.href = decodeURIComponent(redirectUri)
				} else {
					window.location = '/payment'
				}
			} else {
				showError('Login failed. Please try again.')
			}
		} catch (err) {
			setLoading(false)
			console.log('err', err)

			if (err.message.includes(409)) {
				console.log('err 409', err)

				return showError('Email already exists. ')
			}

			showError('An unexpected error occurred. Please try again.')
		}
	}

	useEffect(() => {
		const handlePostMessage = event => {
			const token = event.data.token

			const params = new URLSearchParams(location.search)

			if (sourceExtension && windowOpender) {
				if (!token) return

				windowOpender?.postMessage(
					{
						source: 'loadfetcherLogin',
						token: token,
					},
					'*'
				)

				setTimeout(() => {
					localStorage.clear()

					window.close()
				}, 1000)
			} else if (params.get('source') === 'extension' && window.opener) {
				if (!token) return

				setSourceExtension(true)

				window.opener.postMessage(
					{
						source: 'loadfetcherLogin',
						token: token,
					},
					'*'
				)

				setTimeout(() => {
					localStorage.clear()
					window.close()
				}, 1000)
			} else {
				token && localStorage.setItem('jwtToken', token)
				event.data.email && localStorage.setItem('email', event.data.email)

				const user = token && jwtDecode(token)
				const trial_end = new Date(user?.trial_end)
				const period_end = new Date(user?.period_end)

				const now = new Date()
				const isExpired = period_end < now || trial_end < now
				const productionExtensionId = 'ihcgicdogclbieclnldfjmlglkolankb'
				const developmentExtensionId = 'omgobnjbempcolkjnfphklponcmlooko'
				const extensionId = productionExtensionId

				if (token && event.data.email && !isExpired) {
					if (token)
						sendTokenToChromeExtension(extensionId, {
							token,
						})

					if (redirectUri) {
						window.location = redirectUri
					} else {
						window.location = '/loadboard'
					}
				} else if (isExpired) {
					if (token)
						sendTokenToChromeExtension(extensionId, {
							token,
						})

					if (redirectUri) {
						window.location = redirectUri
					} else {
						window.location = '/plans'
					}
				}
			}
		}

		window.addEventListener('message', handlePostMessage)

		return () => {
			window.removeEventListener('message', handlePostMessage)
		}
	}, [location])

	useEffect(() => {
		setState(prevState => ({
			...prevState,
			password2: prevState.password,
		}))
	}, [state.password])

	return (
		<SlimLayout>
			{!sourceExtension && (
				<div className="flex">
					<>
						<Link to="/" aria-label="Home">
							<Logo
								color={'text-gray-600 text-lg'}
								height={'45px'}
								width={'45px'}
							/>
						</Link>
					</>
				</div>
			)}

			<h2 className="mt-6 mb-4 text-lg font-semibold text-gray-900">
				Get started today
			</h2>

			<button
				className="google-button w-full inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded-sm bg-white hover:bg-gray-50 focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-indigo-500"
				type="button"
				onClick={() => {
					const w = 500
					const h = 600
					const y = window.top.outerHeight / 2 + window.top.screenY - h / 2
					const x = window.top.outerWidth / 2 + window.top.screenX - w / 2

					window.open(
						googleAuthUrl,
						'Google Sign-In',
						`toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`
					)
				}}
			>
				<img
					src={googleIcon}
					alt="Google"
					style={{ width: '20px', height: '20px' }}
				/>

				<p className="btn-text">Continue with Google</p>
			</button>

			<div className="divider text-gray-800">OR</div>

			<form
				onSubmit={onSubmit}
				className="mt-1 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-2"
			>
				{state.errorText && (
					<div className="error-message text-red-500 mt-2">
						{state.errorText}
					</div>
				)}

				<TextField
					className="col-span-full -my-2"
					label="Full name"
					id="name"
					name="name"
					type="text"
					autoComplete="given-name"
					required
					onChange={onChange}
				/>

				<TextField
					className="col-span-full -my-2"
					label="Email address"
					id="email"
					name="email"
					type="email"
					autoComplete="email"
					required
					onChange={onChange}
				/>
				<TextField
					className="col-span-full -my-2"
					label="Password"
					id="password"
					name="password"
					type="password"
					autoComplete="new-password"
					required
					onChange={onChange}
				/>

				<div className="col-span-full">
					<button
						type="submit"
						className="submit-button group inline-flex items-center justify-center rounded-full py-2 px-4 text-sm font-semibold bg-blue-600 text-white hover:text-slate-100 hover:bg-blue-500 active:bg-blue-800 active:text-blue-100 focus:outline-none focus-visible:outline-blue-600 w-full"
					>
						{loading && (
							<span className="loading loading-spinner loading-md mr-4"></span>
						)}

						<span>
							Sign Up <span aria-hidden="true">&rarr;</span>
						</span>
					</button>

					<p className="mt-6 text-sm text-gray-700">
						Already registered?{' '}
						<Link
							to={`/account/login${
								sourceExtension ? '?source=extension&token=' + userToken : ''
							}`}
							className="font-medium text-blue-600 hover:underline"
						>
							Sign in
						</Link>{' '}
						to your account.
					</p>
				</div>
			</form>
		</SlimLayout>
	)
}
