import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import dayjs from 'dayjs'
import * as lightweightCharts from 'lightweight-charts'

import { queryFormatter } from '../loadboardHelpers'
import { baseURL } from '../../../config'

const LoadPriceChart = ({ isDarkMode, load }) => {
	const chartContainerRef = useRef(null)
	const chartRef = useRef(null)
	const intervalRef = useRef(null)
	const [priceStats, setPriceStats] = useState({ min: 0, max: 0, avg: 0 })

	const fetchData = async () => {
		try {
			const response = await axios.post(`${baseURL}/api/loads/similar`, {
				...queryFormatter(load),
				limitDays: 7,
			})

			let rawData = response.data.loads.map(load => {
				const dateTime = dayjs(load.pickupDate).unix()
				return {
					time: dateTime,
					value: load.price_per_mile,
				}
			})

			if (rawData.length > 0) {
				const prices = rawData
					.map(item => item.value)
					.filter(price => !isNaN(price) && price !== null)
				if (prices.length > 0) {
					const min = Math.min(...prices)
					const max = Math.max(...prices)
					const avg =
						prices.reduce((sum, price) => sum + price, 0) / prices.length
					setPriceStats({ min, max, avg })
				}
			}

			const groupedData = rawData.reduce((acc, item) => {
				if (acc[item.time]) {
					acc[item.time].push(item.value)
				} else {
					acc[item.time] = [item.value]
				}
				return acc
			}, {})

			let data = Object.entries(groupedData).map(([time, values]) => {
				return {
					time: parseInt(time),
					value: values.reduce((a, b) => a + b, 0) / values.length,
					formattedTime: dayjs.unix(time).format('ddd MMM D YYYY h:mm:ss A z'),
				}
			})

			data.sort((a, b) => a.time - b.time)

			const chartOptions = {
				width: 600,
				height: 180,
				layout: {
					textColor: isDarkMode ? 'white' : 'black',
					background: {
						type: 'solid',
						color: isDarkMode ? '#1c2128' : 'white',
					},
				},
				timeScale: {
					timeVisible: true,
					secondsVisible: false,
					timezone: 'America/Los_Angeles',
				},
				crosshair: {
					mode: lightweightCharts.CrosshairMode.Normal,
				},
			}

			if (!chartRef.current) {
				chartRef.current = lightweightCharts.createChart(
					chartContainerRef.current,
					chartOptions
				)
			}
			const areaSeries = chartRef.current.addAreaSeries({
				lineColor: isDarkMode ? '#2962FF' : '#00b5b5',
				topColor: isDarkMode ? '#2962FF' : '#00b5b5',
				bottomColor: isDarkMode
					? 'rgba(41, 98, 255, 0.28)'
					: 'rgba(0, 181, 181, 0.28)',
			})

			areaSeries.setData(data)
			chartRef.current.timeScale().fitContent()
		} catch (error) {
			if (error.response?.status === 429) {
				console.warn('Rate limit exceeded. Please wait and try again later.')
			} else {
				console.error('An unexpected error occurred:', error)
			}
		}
	}

	useEffect(() => {
		fetchData()

		intervalRef.current = setInterval(fetchData, 30000)

		return () => {
			if (intervalRef.current) {
				clearInterval(intervalRef.current)
			}
			if (chartRef.current) {
				chartRef.current.remove()
				chartRef.current = null
			}
		}
	}, [load, isDarkMode])

	return (
		<div style={{ width: 'auto' }}>
			<div className="text-sm mb-1" style={{ height: '20px' }}>
				Min: ${priceStats.min.toFixed(2)}, Max: ${priceStats.max.toFixed(2)},
				Avg: ${priceStats.avg.toFixed(2)}
			</div>
			<div ref={chartContainerRef} style={{ height: '180px' }} />
		</div>
	)
}

export default LoadPriceChart
