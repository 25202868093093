import React, { useState, useRef, useEffect } from 'react'

import { ArrowsRightLeftIcon } from '@heroicons/react/24/outline'

import { SelectField, TextField } from './Filters/FiltersFields'
import './loadboard.css'

import SavedSearchesControls from './SavedSearchControls'
import NotificationControls from './NotificationControls'

// import ToggleFilter from './ToggleFilter'
import OriginFilters from './Filters/OriginFilters'
import DestinationFilters from './Filters/DestinationFilters'
import IncludeExcludeFilter from './Filters/InclueOrExcludeBroker'
import UserSearches from './Searches/UserSearches'
import UserNewSearch from './Searches/UserNewSearch'

import MultiSelectSource from './Filters/MultiSelectSource'

import { sourcesList } from './loadboardHelpers'

const getGradesAboveAndEqual = selectedGrade => {
	const grades = [
		'A_PLUS',
		'A',
		'B_PLUS',
		'B',
		'C_PLUS',
		'C',
		'D_PLUS',
		'D',
		'F',
	]
	const selectedIndex = grades.indexOf(selectedGrade)
	return selectedIndex >= 0 ? grades.slice(0, selectedIndex + 1) : []
}

export default function LoadBoardFilters(props) {
	const {
		filters,
		setFilters,
		isDarkMode,
		refetch,
		resetFilters,
		isAudioOn,
		setAudioOn,
		setIsAutoRefresh,
		isAutoRefresh,
	} = props

	const [isFiltersCollapsed, setIsFiltersCollapsed] = useState(true)
	const [countdown, setCountdown] = useState(10)
	const [isSearchModalOpen, setIsSearchModalOpen] = useState(false)
	const [isNewSearchModalOpen, setIsNewSearchModalOpen] = useState(false)

	const handleRefresh = () => {
		refetch()

		setCountdown(10)
	}

	useEffect(() => {
		if (isAutoRefresh && countdown > 0) {
			const timerId = setInterval(() => {
				setCountdown(countdown - 1)
			}, 1000)
			return () => clearInterval(timerId)
		} else if (countdown === 0) {
			handleRefresh()
			setCountdown(10)
		}
	}, [isAutoRefresh, countdown, handleRefresh])

	const dropdownRef = useRef(null)

	const handleDocumentClick = event => {
		if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
			setIsFiltersCollapsed(true)
		}
	}

	// Close filter dropdown when user clicks outside of it
	useEffect(() => {
		document.addEventListener('mousedown', handleDocumentClick)

		return () => {
			document.removeEventListener('mousedown', handleDocumentClick)
		}
	}, [])

	const handleFilterChange = e => {
		const { name, value } = e.target

		setFilters({
			...filters,
			paginateSize: 1,
			[name]: value,
		})
	}

	const multiSelectHandler = e => {
		setFilters({
			...filters,
			paginateSize: 1,
			sourceType: e,
		})
	}

	const brokerSelectHandler = e => {
		setFilters({
			...filters,
			paginateSize: 1,
			excludeBroker: e,
		})
	}

	const handleOriginChange = origin => {
		const pickupCity = origin?.city || ''
		const pickupState = origin?.state_id || ''
		const pickupStateName = origin?.state_id || ''
		const pickupLat = origin?.lat || ''
		const pickupLng = origin?.lng || ''

		setFilters(prevFilters => ({
			...prevFilters,
			paginateSize: 1,
			pickupCity,
			pickupState,
			pickupStateName,
			pickupLat,
			pickupLng,
			origin,
		}))
	}

	const handleDestinationChange = destination => {
		const dropLat = destination?.lat || ''
		const dropLng = destination?.lng || ''
		const dropCity = destination?.city || ''
		const dropState = destination?.state_id || ''
		const dropStateName = destination?.state_id || ''

		setFilters(prevFilters => ({
			...prevFilters,
			paginateSize: 1,
			dropLat,
			dropLng,
			dropCity,
			dropState,
			dropStateName,
			destination,
		}))
	}

	const styles = isDarkMode
		? {
				backgroundColor: '#363e47',
				color: '#C9D1D9',
				borderColor: '#30363D',
		  }
		: {
				backgroundColor: '#ffffff',
				color: '#000000',
				borderColor: '#cccccc',
		  }

	const labels = isDarkMode
		? {
				color: '#C9D1D9',
				borderColor: '#30363D',
		  }
		: {
				color: '#000000',
				borderColor: '#cccccc',
		  }

	const swapOriginAndDestination = () => {
		// Swapping origin and destination values
		const {
			pickupCity,
			pickupState,
			pickupStateName,
			pickupLat,
			pickupLng,
			origin,
			dropLat,
			dropLng,
			dropCity,
			dropState,
			dropStateName,
			destination,
		} = filters

		setFilters(prevFilters => ({
			...prevFilters,
			paginateSize: 1,
			pickupCity: dropCity,
			pickupState: dropState,
			pickupStateName: dropStateName,
			pickupLat: dropLat,
			pickupLng: dropLng,
			origin: destination,
			dropLat: pickupLat,
			dropLng: pickupLng,
			dropCity: pickupCity,
			dropState: pickupState,
			dropStateName: pickupStateName,
			destination: origin,
		}))
	}

	const handleCheckboxToggle = (filterName, value) => {
		setFilters({
			...filters,
			paginateSize: 1,
			[filterName]: value,
		})
	}

	return (
		<div
			className="px-7 py-6"
			style={{
				position: 'relative',
				backgroundColor: isDarkMode ? '#1c2126' : '#f5f5f7',
			}}
		>
			<div
				className="max-w-screen-xl mx-auto rounded-md shadow-md p-2 "
				style={{ backgroundColor: isDarkMode ? '#22272d' : '#f5f5f7' }}
			>
				{/* Top control panel */}
				<div className="flex justify-between -mb-2 mx-4">
					<SavedSearchesControls
						openModal={() => setIsSearchModalOpen(true)}
						openNewSearchModal={() => setIsNewSearchModalOpen(true)}
					/>
					<UserSearches
						isModalOpen={isSearchModalOpen}
						closeModal={() => setIsSearchModalOpen(false)}
						setFilters={setFilters}
						isDarkMode={isDarkMode}
					/>
					<UserNewSearch
						isModalOpen={isNewSearchModalOpen}
						closeModal={() => setIsNewSearchModalOpen(false)}
						filters={filters}
						isDarkMode={isDarkMode}
					/>
					<NotificationControls
						handleRefresh={handleRefresh}
						isAutoRefresh={isAutoRefresh}
						setIsAutoRefresh={setIsAutoRefresh}
						countdown={countdown}
						isDarkMode={isDarkMode}
						isAudioOn={isAudioOn}
						setAudioOn={setAudioOn}
					/>
				</div>

				{/* origin and destination fields start */}
				<div className="grid grid-cols-12 gap-0 text-gray-200 flex justify-between">
					{/* Origin */}
					<OriginFilters
						filters={filters}
						handleOriginChange={handleOriginChange}
						labels={labels}
						handleFilterChange={handleFilterChange}
						styles={styles}
						isDarkMode={isDarkMode}
					/>

					{/* Icon */}
					<div className="col-span-1 flex items-center justify-center">
						<ArrowsRightLeftIcon
							onClick={swapOriginAndDestination}
							className=" mt-6 h-6 w-6 text-gray-300 cursor-pointer"
							style={{ color: isDarkMode ? '#C9D1D9' : '#000000' }}
						/>
					</div>

					{/* Destination */}
					<DestinationFilters
						filters={filters}
						handleDestinationChange={handleDestinationChange}
						labels={labels}
						handleFilterChange={handleFilterChange}
						styles={styles}
						isDarkMode={isDarkMode}
					/>
					{/* Equipment */}
					<div className="equipment-select select-wrap col-span-1 flex">
						<label
							style={{
								...labels,
								backgroundColor: isDarkMode ? '#22272c' : '#f5f5f7',
								color: isDarkMode ? '#C9D1D9' : '#3a4353',
							}}
						>
							Equipment
						</label>
						<select
							name="equipType"
							onChange={handleFilterChange}
							className=" mt-1 p-2 rounded-md w-full flex-grow"
							style={styles}
							firstvalue="Any"
							value={filters.equipType}
						>
							<option value="">Any</option>
							<option value={'POWER ONLY'}>Power Only</option>
							<option value={'DRY VAN'}>Dry Van</option>
						</select>
					</div>
				</div>
				{/* origin and destination fields end */}

				{/* Dropdown Additional Filters */}
				<div
					ref={dropdownRef}
					className={`dark-gray max-w-screen-xl -ml-2 rounded-md grid grid-cols-1 md:grid-cols-4 lg:grid-cols-6 gap-4 w-full transition-all py-4 px-4 bg-white absolute mt-3 ${
						isFiltersCollapsed ? 'hidden' : 'block'
					} text-gray-700`}
					style={{
						minHeight: '270px',
						zIndex: 1000,
						backgroundColor: `${isDarkMode ? '#363e47' : 'white'}`,
						boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
					}}
				>
					<button
						className="absolute top-2 right-2"
						onClick={() => setIsFiltersCollapsed(true)}
					>
						&times;{' '}
					</button>

					{/* First row */}
					<div className="grid grid-cols-6 col-span-full gap-4">
						<div className="col-span-1">
							<label
								className={`${isDarkMode ? 'text-gray-200' : 'text-gray-800'}`}
							>
								Performance Score
							</label>
							<SelectField
								id="carrierGrade"
								name="carrierGrade"
								className="w-full"
								onChange={e => {
									const selectedGrade = e.target.value
									const gradesAboveAndEqual = selectedGrade
										? getGradesAboveAndEqual(selectedGrade)
										: []
									setFilters({
										...filters,
										paginateSize: 1,
										carrierGrades: gradesAboveAndEqual,
									})
								}}
								value={
									filters.carrierGrades.length > 0
										? filters.carrierGrades[filters.carrierGrades.length - 1]
										: ''
								}
								style={{
									backgroundColor: '#ffffff',
									color: '#686869',
									borderColor: '#cfcfcf',
								}}
							>
								<option value="">Any</option>
								<option value="A_PLUS">A+</option>
								<option value="A">A & up</option>
								<option value="B_PLUS">B+ & up</option>
								<option value="B">B & up</option>
								<option value="C_PLUS">C+ & up</option>
								<option value="C">C & up</option>
								<option value="D_PLUS">D+ & up</option>
								<option value="D">D & up</option>
								<option value="F">F & up</option>
							</SelectField>
						</div>

						<div className="col-span-1">
							<label
								className={`${isDarkMode ? 'text-gray-200' : 'text-gray-800'}`}
							>
								Max stops
							</label>
							<SelectField
								id="weight"
								name="stopCount"
								className="w-full"
								onChange={handleFilterChange}
								value={filters.stopCount}
								style={{
									backgroundColor: '#ffffff',
									color: '#686869',
									borderColor: '#cfcfcf',
								}}
							>
								<option value="0">Stops (Any)</option>
								<option value="2">2 stops</option>
								<option value="3">3 stops</option>
								<option value="4">4 stops</option>
								<option value="5">5 stops</option>
								<option value="6">6 stops</option>
								<option value="7">7 stops</option>
								<option value="8">8 stops</option>
								<option value="9">9 stops</option>
							</SelectField>
						</div>

						<div className="col-span-1">
							<label
								className={`${isDarkMode ? 'text-gray-200' : 'text-gray-800'}`}
							>
								Distance (min)
							</label>
							<TextField
								id="weight"
								type="number"
								name="distance"
								className="w-full"
								onChange={handleFilterChange}
								value={filters.distance}
								style={{
									backgroundColor: '#ffffff',
									color: '#686869',
									borderColor: '#cfcfcf',
								}}
							/>
						</div>

						<div className="col-span-1">
							<label
								className={`${isDarkMode ? 'text-gray-200' : 'text-gray-800'}`}
							>
								Distance (max)
							</label>
							<TextField
								id="weight"
								type="number"
								name="maxdistance"
								className="w-full"
								value={filters.maxdistance}
								onChange={handleFilterChange}
								style={{
									backgroundColor: '#ffffff',
									color: '#686869',
									borderColor: '#cfcfcf',
								}}
							/>
						</div>

						<div className="col-span-1">
							<label
								className={`${isDarkMode ? 'text-gray-200' : 'text-gray-800'}`}
							>
								Driver Type
							</label>
							<SelectField
								id="driverType"
								name="driverType"
								className="w-full"
								onChange={handleFilterChange}
								value={filters.driverType || ''}
								style={{
									backgroundColor: '#ffffff',
									color: '#686869',
									borderColor: '#cfcfcf',
								}}
							>
								<option value="">Any</option>
								<option value="Solo">Solo</option>
								<option value="Team">Team</option>
							</SelectField>
						</div>
					</div>

					{/* Second row */}
					<div className="grid grid-cols-6 col-span-full gap-4 mt-4">
						<div className="col-span-1">
							<label
								className={`${isDarkMode ? 'text-gray-200' : 'text-gray-800'}`}
							>
								$Per mile(min)
							</label>
							<TextField
								id="pricePerMile"
								type="number"
								name="pricePerMile"
								className="w-full"
								onChange={handleFilterChange}
								value={filters.pricePerMile || ''}
								style={{
									backgroundColor: '#ffffff',
									color: '#686869',
									borderColor: '#cfcfcf',
								}}
							/>
						</div>

						<div className="col-span-1">
							<label
								className={`${isDarkMode ? 'text-gray-200' : 'text-gray-800'}`}
							>
								$Payout (min)
							</label>
							<TextField
								id="price"
								type="number"
								name="price"
								className="w-full"
								onChange={handleFilterChange}
								value={filters.price || ''}
								style={{
									backgroundColor: '#ffffff',
									color: '#686869',
									borderColor: '#cfcfcf',
								}}
							/>
						</div>

						{/* Checkboxes */}
						<div className="col-span-2 flex items-center gap-4">
							<IncludeExcludeFilter
								label="Bobtail"
								onToggle={handleCheckboxToggle}
								filterName="bobtail"
								initialState={filters.bobtail}
							/>
							<IncludeExcludeFilter
								label="AI Bookable"
								onToggle={handleCheckboxToggle}
								filterName="isAIEnabled"
								initialState={filters.isAIEnabled}
							/>
						</div>
					</div>

					<div className="col-span-full flex justify-start mt-4">
						<button
							className="text-sm text-blue-600 hover:underline"
							onClick={() => setIsFiltersCollapsed(!isFiltersCollapsed)}
						>
							{isFiltersCollapsed ? 'Expand Filters' : 'Collapse Filters'}
						</button>
					</div>
				</div>

				<div className="flex justify-between items-center mt-4">
					<button
						className="mx-2 text-sm text-blue-600 hover:underline"
						onClick={() => setIsFiltersCollapsed(!isFiltersCollapsed)}
					>
						{isFiltersCollapsed ? 'Expand Filters' : 'Collapse Filters'}
					</button>

					{/* Fix here */}

					<div className="flex items-center toggle-container">
						<button
							className="text-blue-600 hover:underline mx-5"
							onClick={resetFilters}
						>
							Clear Filters
						</button>
					</div>
				</div>
			</div>
		</div>
	)
}
